.input-chat {
    font-size: 12px;
    height: 35px;
    margin-bottom: 15px;
    border: 1px solid lightgrey;
    padding-left: 10px;
    text-overflow: ellipsis;
    width: 19vw !important;
border-radius: 4px;
}

.textarea {
    font-size: 12px;
    height: 126px;
    margin-bottom: 15px;
    border: 1px solid lightgrey;
    padding-left: 10px;
    text-overflow: ellipsis;
    width: 40vw !important;
    margin-top: -3%;

}

.css-f6f3yk-control{
    height: 35px !important;
    min-height: 36px !important;
    font-size: 12px !important;
}
.join_us_textarea {
    min-height: 154px !important;
}

.table {
    background: linear-gradient(268.5deg, #134CBC -38.36%, #139C32 156.67%);
}

.career_form_input {
    display: block;
    margin-bottom: 4px;
    font-weight: 100;
    opacity: 80%;
    font-family: 'Poppins';
    font-size: medium;
}

.career_btn {
    border: 0.9px solid #B4BEC8;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 96px;
    gap: 7.17px;
    border-radius: 8.96px;
    opacity: 0px;

}

.popup_dailog div div{
    max-width: 70vw; 
    overflow-y: unset !important;
}

.services_btn{
    max-width: 136px !important;
}

.contactus {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #161011;
    opacity: 0.8;
}

.contact-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.contact-item h5 {
    margin: 0;
}

.contact-item p {
    margin-left: 1%;
    margin: 0;
}

.cookie_dialog {
    background-color: none !important;
    box-shadow: none !important;
}

.cookie_btn {
    padding: 10px 15px;
    background-color: white;
    border: 1px solid #EFF0F6 !important;
    box-shadow: ' 0px 4px 8px 0px #4A3AFF14';
    width: 100%;
    max-width: 136px;
    margin-left: 2%;
    border-radius: '56px' !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
}

.cookie_btn p {
    color: #3B3B3B !important;
}

.arrow_icons {
    position: relative;
    font-size: 20px;
    margin-left: 8px;
    color: #134CBC;
}

.images {
    margin-top: 10%;
}

.contact_btn{
    margin-bottom: 5%;
}
.cookie-modal {
    position: 'absolute';
    top: '50%';
    left: '50%';
    transform: 'translate(-50%, -50%)';
    width: 300px;
    background-color: 'white';
    border: '2px solid #4CAF50';
    border-radius: '8px';
    padding: '20px';
    box-shadow: '0 4px 8px rgba(0, 0, 0, 0.2)';
    outline: 'none';
}

/* blog css */

.blog_contain {
    text-decoration: none;
    color: #134CBC;
    font-size: 15px;
    position: relative;
    top: -2px;
}

/* popup css */
.main_header {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 3%;
}

.popup_header {
    font-size: xx-large;
    color: #FEDD47;
}
.dialog_header{
    background-color: #134CBC !important;
    padding: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    position: relative;
    z-index: 1;
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
}
.popup_body {
    display: flex;
}

.popup_image {
    background-color: #DFEAFF;
    height: 63vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: -66px;
    left: 13%;
    transform: translateX(-50%);
    z-index: 10;
    padding: 20px;
}


/* header technology icons */
.header_tech_main {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: -11px;
    position: relative;
    /* bottom: 17px; */
}

.header_img {
    height: 20px;
    width: 20px;
    margin-left: 10px;
}

.horizontal_line_container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.horizontal_line {
    width: 100%;
    height: 2px;
    background-color: #ccc;
}

.horizontal_gap {
    width: 7%;
    height: 2px;
}
.horizontal_gap1 {
    width: 2%;
    height: 2px;
}
.vertical_line {
    align-self: stretch;
    margin: 0 20px;
    width: 1px;
    background-color: #ccc;
    position: relative;
    left: 9px;
    height: 228px;
}

.vertical_line2 {
    align-self: stretch;
    margin: 0 20px;
    width: 1px;
    left: -4px;
    position: relative;
    height: 228px !important;
    background-color: #ccc
}
.vertical_line_ {
    align-self: stretch;
    margin: 0 20px;
    width: 1px;
    background-color: #ccc;
    position: relative;
    left: 2px;
    height: 228px;
}



.header_tech_main:hover {
    background-color: rgb(19, 76, 188) !important;
    border-radius: 10px !important;
}

.header_tech_main a {
    color: inherit;
    transition: color 0.3s ease;
}

.header_tech_main:hover a {
    color: white !important;
    border-radius: 10px;
    background-color: rgb(19, 76, 188) !important;
}

.tech_main_box {
    display: flex !important;
    justify-content: center;
}

.technology_btn {
    padding: 0px 15px;
    border-radius: 5px;
    width: 100%;
    max-width: 188px;
    height: 50px;
    margin-top: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 0.81px solid #134CBC;
    transition: background-color 0.3s;
}

.technology_btn p {
    color: #134CBC !important;
}

.tech_link {
    font-size: smaller;
    color: #139C32;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 10px;
}

/* contact page  */
.contact_link {
    display: flex;
    align-items: center;
    position: relative;
    background-color: #139C32;
    border-radius: 5px;
    padding: 5px 10px;
    transition: transform 0.3s ease;
    overflow: hidden;
}

.contact_btn:hover .contact_link {
    transform: translateX(-70%);
}

.contact_label p {
    margin-top: -2px !important;
    font-size: small;
    margin-left: 7px !important;
}

.popup_card::-webkit-scrollbar {
    display: none; 
}

.popup_card {
    -ms-overflow-style: none;  
    scrollbar-width: none; 
}



.imgstyle{
    width: 90vw;
    margin-top: 20%;
}

.services_card{
    box-shadow: 0px 38px 90px 0px #00000040 !important;
}

.services_card:hover {
    transform: scale(1.05);
}

@media (min-width:1921px){
    .menu-paltform {
        left: 28% !important;
    }
    .solution-menu{
        left: 38%;
    }
    .company-menu{
        left: 35%;
    }
}
@media (min-width:2050px){
    .menu-paltform {
        left: 31% !important;
    }
}

@media(max-width:1880px){
    .solution-menu{
        left: -46%;
     }
     .header_menu {
        left: -75%;
    }
}
@media (max-width:1780px) {
    .solution-menu {
        left: -49%;
    }
    .header_menu{
        left: -81%;
    }

    .menu-paltform {
        left: -83%;
    }
}

@media (max-width:1652px) {
   .nav-list{
    width: 29vw !important;
   }
    .header_menu {
        left: -96%;
    }
    .solution-menu {
        left: -60%;
    }
    .menu-paltform {
        left: -90%;
    }
}

@media (max-width:1529px){
    .menu-paltform {
        left: -90%;
        margin-left: -14%;
    }   
    .header_menu {
        margin-left: -46%;
    }
}
@media (max-width:1466px){
    .menu-paltform {
        left: -90%;

        margin-left: -19%;
    }
}
@media (max-width:1458px) {
    .nav-list {
        width: 17vw !important;
    }
    .company-menu{
        right: 13%;
    }
    .header_menu {
        left: -198%;
        margin-left: -114%;
    }
    .solution-menu {
        margin-left: -88%;
        left: -77%;
    }
    .menu-paltform {
        margin-left: -98%;
        left: -123%;
    }
    .menu-paltform{
        left: 20%;
    }
}
@media (max-width:1366px) {
    .textarea {
        width: 56vw !important;
    }

    .popup_textarea {
        width: 32vw !important;

    }
    .input-chat {
        width: 27vw !important;
    }

    .popup_input {
        width: 16vw !important;
    }

    .technology_triangle{
        margin-left: -39%;
    }
    .menu-paltform {
        left: -138%;
    }
    .header_menu {
        left: -93%;
        margin-left: -77%;
    }

}
/* technology page css */

.tech_img_main {
    height: 50px;
    text-align: center;
    width: 50px;
    border-radius: 10px;
}

.tech_img_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.tech-content {
    display: flex;
    flex-direction: column;
    width: 45vw;
}

.technology-main-card-component,
.technology-main-card-component_2 {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 40vw;
}

.technology_main_card_2 {
    display: flex;
    justify-content: center;
    background-color: #F2F5FB;
}

.cookiebox-card{
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
    padding: 20px;
    box-shadow: none !important;
    border-radius: 20px !important;
    border: 1px solid #D9DBE9;
    width:90%;
    max-width: 900px;
}

.payment_form{
    max-width: 904px; 
    margin: auto; 
    padding: 20px; 
    box-shadow: 2.61px 13.04px 36.51px 0px #134CBC26
}
.dropdown-card{
    margin-top: 10px;
    margin-right: 10px !important;
    box-shadow: 0px 66.74px 103.15px 20.34px #00000025;
  

}
.dropdown-sub-card{
    margin-left: 15px !important;
    margin-right: 15px !important;
}
.technology_cards{
    box-shadow: 3.1px 12.42px 31.04px 0px #4A99D333;
}

.slide_btn{
    background-color: white !important;
    border: 1px solid  #134CBC !important;
    color: black !important;
    font-size: 20px !important ;
    height: 45px;
    min-width: 40px !important;
    width: 45px;

}

@media (max-width:1305px) {
    .input-chat {
        width: 30vw !important;
    }

    .popup_input {
        width: 15vw !important;
    }

    .textarea {
        width: 63vw !important;
    }

    .popup_textarea {
        width: 29vw !important;

    }
    .menu-paltform {
        left: -149%;
    }
}

@media (max-width:1257px) {
    .popup_image {
        left: 20% !important;
    }

    .popup_select{
        width: 34vw !important;
    }

    .popup_input {
        width: 34vw !important;
    }

    .popup_textarea {
        width: 37vw !important;
    }
    .solution-menu {
        left: -97%;
    }
    .technology_triangle {
        margin-left: -45%;
    }
}

@media (max-width: 1170px) {

    .technology_cards {
        order: -1 !important;
        width: 20vw !important;
    }

    .main_tech_content {
        order: 1 !important;
        /* Content will be displayed below the cards */
    }

    .tech-content {
        display: flex;
        flex-direction: column;
        width: 84vw;
    }

    .technology-main-card-component,
    .technology-main-card-component_2 {
        display: flex !important;
        flex-direction: column !important;
        text-align: left !important;
        width: 84vw !important;
    }

    .technology_main_card_2 {
        justify-content: center;
    }

}

@media (max-width:891px) {
    .popup_select {
        width: 18.5vw !important;
    }
    .popup_textarea {
        width: 39vw !important;
        margin-left: 13px;
    }
}

@media (max-width:916px) {
    .input-chat {
        width: 30vw !important;
    }

    .popup_input {
        width: 30vw !important;
    }

    .popup_select{
        width: 30vw !important;

    }
    .textarea {
        width: 65vw !important;
    }

    .popup_textarea {
        width: 39vw !important;
    }
}

@media (max-width:866px) {
    .popup_image {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        align-items: center;
        top: -3px;
        right: -119px;
        width: 50vw;
        margin-left: 30%;
        height: 22vh;
    }
   
    .popup_img_container {
    height: 20vh;
    }

    .popup_card {
        position: relative;
        padding-top: 27% !important;
        padding-left: 9% !important;
    }

    .popup_input {
        width: 48vw !important;
    }

    .popup_select {
        width: 48vw !important;
    }

    .popup_textarea {
        width: 48vw !important;
        margin-left: 0px;
    }

    .close-btn-container-popup {
        top: -65px;
        left: 98%;
    }
}

@media(max-width:844px) {
    .popup_input {
        width: 52vw !important;
    }

    .popup_select {
        width: 52vw !important;
    }

    .close-btn-container-popup {
        top: -70px;
        left: 98%;
    }
}

@media (max-width:786px){
    .close-btn-container-popup {
        top: -110px;
        left: 98%;
    }
}
@media (max-width:731px) {
    .popup_card {
        padding-top: 42% !important;
        padding-left: 9% !important;
    }
}

@media (max-width:547px) {
    .input-chat {
        width: 80vw !important;
    }

    .popup_input {
        width: 48vw !important;
    }

    .textarea {
        width: 80vw !important;
    }

    .popup_textarea {
        width: 50vw !important;
    }

    .close-btn-container-popup {
        top: -129px;
        left: 98%;
    }
    .popup_select {
        width: 48vw !important;
    }
    .technology_cards {
        width: 30vw !important;
    }
}

@media (max-width:425px) {
    .popup_card {
        padding-top: 70% !important;
    }

    .close-btn-container-popup {
        top: -155px;
        left: 98%;
    }
    .home_text{
        font-size: 15px !important;
        top: 11%
    }

    .imgstyle{
        width: 70vw;
    }
}

@media(max-width:399px){
    .close-btn-container-popup {
        top: -221px;
        left: 90%;
    }
 }

@media (max-width: 700px) {
    .cookies_main_btn {
        display: flex !important;
        flex-direction: row !important;
        gap: 10px !important;
        flex-wrap: wrap
    }
}

@media (max-width: 595px) {
    .contact-info {
        align-items: center;
        flex-direction: column;
    }

    .contact-item {
        flex-direction: row;
    }
}

