@font-face {
  font-family: 'Poppins';
  src: url('/src/fonts/Poppins/Poppins-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins';
  src: url('/src/fonts/Poppins/Poppins-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Poppins';
  src: url('/src/fonts/Poppins/Poppins-Medium.ttf'); 
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins';
  src: url('/src/fonts/Poppins/Poppins-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Poppins';
  src: url('/src/fonts/Poppins/Poppins-Bold.ttf');
  font-weight: 700;
}

/* Add other font weights similarly */


.main_contact{
  margin-top: 10% ;
  text-align: center;
   margin-right: 10%;
   margin-left: 10%
}
.digital_page{
  background-image: url(./images/digital.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative; 
}
.header_card_image{
  background-image: url(./icons/header/header_img/Group.png);
  background-size: cover; 
  background-position: center; 
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative; 
  overflow: hidden;
}

.header_card_image_mobile{
  background-image: url(./icons/header/header_img/Group.png);
  background-size: cover; 
  background-position: center;
  position: relative; 
  overflow: hidden;
}
.mob_page{
  background-image: url(./images/mob_app.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative; 
}
.web_page{
  background-image: url(./images/web_app.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative; 
}
.soft_page{
  background-image: url(./images/software_app.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative; 
}
.java_page{
  background-image: url(./images/java_page.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative; 
}
.front_page{
  background-image: url(./images/front-end.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative; 
}
.database_page{
  background-image: url(./images/database.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative; 
}
.internet_page{
  background-image: url(./images/internet.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative; 
}
.open_source{
  background-image: url(./images/open_source.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative; 
}

.flutter_page{
  background-image: url(./images/flutter.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative; 
}

.rsc_page{
  background-image: url(./images/rcs_msg.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative; 
}

.pbx_page{
  background-image: url(./images/pbx.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative; 
}

.whatsapp_page{
  background-image: url(./images/whatsapp.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative; 
}
.bulk_page{
  background-image: url(./images/bulk_sms.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative; 
}
.mail_page{
  background-image: url(./images/mail.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative; 
}
.technology_page{
  background-image: url(./images/technology.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative; 
}
.crm_page{
  background-image: url(./images/comapny_bg-img.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative; 
}
.cms_page{
  background-image: url(./images/cms.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative; 
}
.e-commerce{
  background-image: url(./images/e-comerce.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative; 
}
.erp_page{
  background-image: url(./images/erp.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative; 
}

.bank_page{
  background-image: url(./images/bank.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative; 
}

.gove_page{
  background-image: url(./images/goverment.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative;
}
.religious_page{
  background-image: url(./images/religious.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative;
}
.edu_page{
  background-image: url(./images/edu.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative;
}

.corporate_page{
  background-image: url(./images/corporate.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative;
}
.politics_page{
  background-image: url(./images/politics.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative;
}
.comm_page{
  background-image: url(./images/e-com.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative;
}

.international_page{
  background-image: url(./images/international.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative;
}

.php_page{
  background-image: url(./images/php.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative;
}
.word_page{
  background-image: url(./images/wordpress.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative;
}

.code_page{
  background-image: url(./images/code_page.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative;
}

.drupal_page{
  background-image: url(./images/drupal.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative;
}
.joomla_page{
  background-image: url(./images/joomla.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative;
}
.magento_page{
  background-image: url(./images/magento.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative;
}

.opencart_page{
  background-image: url(./images/opencart.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative;
}
.payment_page{
  background-image: url(./images/payment2.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative;
}
.terms_conditions{
  background-image: url(./images/terms.png); 
  background-size: cover; 
  background-position: center; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative;
}
.leeway_page{
  background-image: url(./images/leeway.png); 
  background-size: cover; 
  height: 50vh;
  display: flex;
  justify-content: center; 
  align-items: center; 
  position: relative;
}

