html {
     scroll-behavior: smooth;
     font-family: poppins !important;
     /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
         'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
         sans-serif; */
 }


 h3,
 h2,
 h1,
 p {
     font-family: poppins !important;
 }

 .triangle {
     position: absolute;
     top: -10px;
     left: 50%;
     margin-left: -15px;
     width: 0;
     height: 0;
     border-left: 15px solid transparent;
     border-right: 15px solid transparent;
     border-bottom: 10px solid white;
 }

 .header {
     box-shadow: 0px 15px 50px 0px #134CBC0D;
     display: flex;
     align-items: center;
     justify-content: space-around;
     color: black !important;
     position: sticky;
     top: 0;
     left: 0;
     right: 0;
     z-index: 999;
     background-color: white;
 }

 .header-link {
     position: relative;
     display: inline-block;
     padding: 10px;
     text-decoration: none;
 }

 .w-joinus {
     display: inline-block;
     white-space: nowrap;
 }

 .w-joinus a.active {
     width: 60px !important;

 }

 .border_line {
     position: absolute;
     bottom: 100%;
     left: 0;
     bottom: 10%;
     right: 0;
     height: 2px;
     background-color: #139C32;
     visibility: hidden;
     transition: visibility 0s, opacity 0.2s linear;
     opacity: 0;
 }

 .header-link:hover .border_line,
 .header-link.active .border_line {
     visibility: visible;
     opacity: 1;
 }

 .nav-list {
     display: flex;
     justify-content: center;
     align-items: center;
     gap: 3% !important;
     margin-left: 17%;
     flex-grow: 1;
 }

 .nav-list a {
     color: #134CBC;
     font-size: 18px;
     text-decoration: none;
 }

 .nav-list a.active {
     font-weight: bold;
 }

.menu-paltform{
    position: absolute ;
    left: 12%;
    background-color: white;
    z-index: 100
 }
.logo {
     height: 100px;
     /* width: 212px; */
 }

.footer_logo {
     height: 100px;
 }

 .sidebar {
     display: none;
 }

 .menu-icon {
     display: none;
 }

 .sidebar.open {
     display: block;
 }

 .sidebar {
     position: absolute !important;
     min-width: 210px !important;
     left: -4px !important;
    width: 61% !important;
     height: 100vh;
     background-color: rgba(255, 255, 255, 0.9);
     transition: transform 0.3s ease !important;
     z-index: 1000;
 }

 .sidebar.open {
     right: 0;
 }

 .sidebar.closed {
     right: -250px;
 }

 .menu-icon {
     cursor: pointer;
     padding: 10px;
     display: flex;
     align-items: center;
     justify-content: center;
 }

 /* header dropdown */

 .dropdown-menu {
     display: flex;
     flex-direction: column;
     position: absolute;
     background-color: #FFFFFF;
     box-shadow: 0px 66.74px 103.15px 70.34px #00000025;
     z-index: 100;
     border-radius: 10px;
 }

 .dropdown-menu a {
     padding: 10px 15px;
     text-decoration: none;
     color: black !important;
 }

 .dropdown-menu a:hover {
     background-color: rgb(19, 76, 188) !important;
     color: white !important;
     border-radius: 10px !important;
 }

 .droppdown_link  span{
  font-size: 17px;
 }

 .tech-dropdown {
     display: flex;
     flex-direction: row;

 }

 .tech-menu {
     left: -57%;
     width: 30vw;
 }

 /* header dropdown */
 .content {
     font-weight: 700;
     line-height: 71.08px;
     text-align: left;
     padding-top: 30px;
 }
 .home-button {
     padding: 10px 15px;
     background-color: #134CBC;
     font-weight: bold;
     border-radius: 5px;
     width: 100%;
     max-width: 136px;
     margin-top: 10px;
     margin-left: 2%;
     cursor: pointer;
     display: flex;
     justify-content: center;
     align-items: center;
     transition: background-color 0.3s;
 }

 .home-button:hover {
     background-color: #0f3a8c;
 }

 .home-button:disabled {
     background-color: #ccc;
     cursor: not-allowed;
 }


 .BackgroundContainer {
     background-image: url('../src/images/Background+Shadow.png');
     background-size: cover;
     background-position: center;
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .Background {
     background-image: url('../src/images/Frame 51.png');
     background-size: cover;
     background-position: center;
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .rsc-container {
     width: 465px !important;
     height: 454px !important;
 }

 .rsc-header {
    height: 60px !important;
     
 }

 .rsc-content {
     height: calc(335px) !important;
     overflow-y: scroll;
     margin-top: 2px;
     padding-top: 6px;
 }

 .call_img {
     margin-left: 10px;
 }
/* 
 .img-container {
     border-radius: 32.21px 0px 0px 0px;
     width: 500px;
     height: 500px;
 } */
/* 
 .card-container {
     display: flex;
     gap: 20px;
     width: 500px;
 } */

 .card {
     background-color: #fff;
     border-radius: 8px;
     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
     padding: 20px;
     text-align: center;
     width: fit-content;
     margin-right: 10%;
 }

 .card:hover {
     transform: scale(1.05);
 }

 
 .card_icon {
     margin-bottom: 15px;
 }

 .card_image {
     max-width: 100px;
     height: auto;
 }

 .card_title {
     font-size: 17px;
     margin: 10px 0;
 }

 .card_description {
     font-size: 15px;
     color: #666;
 }

 /* services */

 .icon-img {
     width: 50px;
     height: 50px;
 }

 .icons {
     border-radius: 12px;
     border-color: #1375BC;
 }
 .icons_blog{
    display: flex;
    align-items: center;
    
 }

 /* events */
 .event_main {
     background-image: url(./images/Frame\ 50.webp);
     background-size: cover;
     background-position: center;
     display: flex;
     justify-content: center;
     align-items: center;
     margin-top: 3%;
 }

 .card_event {
    height: 535px;
    display: flex;
    align-items: center;
 }

 .event-img {
    width: 213px;
    height: 213px;
    position: relative;
    bottom: 0;
    border-left: 4px solid #134CBC;
    border-bottom: 7px solid #134CBC;
    box-sizing: border-box;
    object-fit: cover;
    border-radius: 50%;
    border-bottom-left-radius: 50%
 }
.event-img2{
    width: 213px;
    height: 213px;
    position: relative;
    bottom: 0px;
    right: 15px;
    border-right: 4px solid rgb(19, 76, 188);
    border-bottom: 7px solid rgb(19, 76, 188);
    box-sizing: border-box;
    object-fit: cover;
    border-radius: 50%;
}
 /* testimonial */
 .testimonial_img {
     background-image: url(./images/Frame\ 51.png);
     background-size: cover;
     background-position: center;
     display: flex;
     justify-content: center;
     align-items: center;
     height: 100%;
 }
 .header_menu {
    position: absolute;
    left: 21%;
    /* max-width: 930px; */
    background-color:#FFFFFF;
    z-index: 100;
    transition: background-color 0.3s ease;

}
 .review_card {
     box-shadow: 0px 16px 24px 15px #0191431A !important;
 }

 .review_card1 {
     box-shadow: 0px 16px 24px 15px #007BBE1A !important;
 }

 .review_card2 {
     box-shadow: 0px 16px 24px 15px #E31E250D !important;
 }

.platform_image{
    height: 323px;
    width: 344px;
    margin-top: -10px;
}
.platform_card{
    border-radius: 30px;
}
 /* footer */

 .footer_card p {
     text-align: left;
     margin-left: 5%;
     font-size: smaller;
 }

 .footer_links {
     display: flex;
     text-align: left;
     width: 173px;
     flex-direction: column;
 }

 .footer_links a {
     text-decoration: none;
     color: white;
     margin: 5px 0;
     transition: text-shadow 0.3s;
     font-size: medium;
     opacity: 0.9
 }

 .footer_links a:hover {
     text-shadow: 0 0 5px rgba(255, 255, 255, 0.8);
 }

 .contact-item {
     display: flex;
     align-items: center;
 }

 .contact-item p {
     font-size: small;
 }
.contact_card{
    padding: 15px;
    max-width: 904px;
    margin-left: auto;
    margin-right:auto;
    box-shadow: 2.61px 13.04px 36.51px 0px #134CBC26 !important ;
}
 .phone-icon {
     margin-right: 10px;
 }

 .close-btn-container-popup {
     position: relative;
     width: 3vw;
     top: -80px;
     left: 96%;
     z-index: 10;
     cursor: pointer;
 }

 /* about page */

 .content_page {
     background-image: url(./images/about_us.png);
     background-size: cover;
     background-position: center;
     height: 50vh;
     display: flex;
     justify-content: center;
     align-items: center;
     position: relative;
 }

 /* infrasrtucture page */

 .infra_page {
     background-image: url(./images/infrastructure.png);
     background-size: cover;
     background-position: center;
     height: 50vh;
     display: flex;
     justify-content: center;
     align-items: center;
     position: relative;
 }

 .infra_card {
     height: 40px;
     background-color: white;
     box-shadow: 0px 6.3px 30px 0px #3D3D3D0D;
 }

 .it_infra {
     position: relative;
     overflow: hidden;
     background-image: url(./icons/Group\ 2.png), url(./icons/Group\ 1.png);
     background-size: auto, auto;
     background-position: left, top right;
     background-repeat: no-repeat, no-repeat;
 }

 .it_infra::before {
     content: "";
     position: absolute;
     top: 0;
     left: 0;
     width: 15%;
     height: 200px;
     background: #1375BC26;
     backdrop-filter: blur(100px);
     z-index: -1;
     opacity: 0.35;
     border-bottom-right-radius: 300px;
 }


 /* achievements */
 .achive_page {
     background-image: url(./images/achievement.png);
     background-size: cover;
     background-position: center;
     height: 50vh;
     display: flex;
     justify-content: center;
     align-items: center;
     position: relative;
 }

 /* our client page */

 .client_page {
     background-image: url(./images/client_bg.png);
     background-size: cover;
     background-position: center;
     height: 50vh;
     display: flex;
     justify-content: center;
     align-items: center;
     position: relative;
 }

 /* career */
 .career_page {
     background-image: url(./images/career.png);
     background-size: cover;
     background-position: center;
     height: 50vh;
     display: flex;
     justify-content: center;
     align-items: center;
     position: relative;
 }

 .file-upload-container {
     height: 154px;
     padding: 15px;
     border: 1px solid lightgrey;
 }

 .drag-drop-zone {
     max-width: 100% !important;
     min-width: fit-content !important;
     display: flex;
     height: 118px;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     padding: 20px 10px;
     border-radius: 10px;
 }

 .drag-drop-zone p {
     font-size: 12px;
     color: #190b56;
 }

 /* specialise */

 .component_bgimg {
     background-image: url(./images/Frame\ 50.png);
     background-size: cover;
     background-position: center;
     justify-content: center;
     align-items: center;
 }

 /* client*/
 .client:hover {
     background-color: #0D9DDA;
     cursor: pointer;
     transform: scale(1.05);
 }

 .client2:hover {
     background-color: #13CEC0;
     cursor: pointer;
     transform: scale(1.05);
 }

 .client3:hover {
     background-color: #EC653B;
     cursor: pointer;
     transform: scale(1.05);
 }

 .client4:hover {
     background-color: #FCAC63;
     cursor: pointer;
     transform: scale(1.05);
 }

 .client5:hover {
     background-color: #9F63C6;
     cursor: pointer;
     transform: scale(1.05);
 }

 .client6:hover {
     background-color: #69D260;
     cursor: pointer;
     transform: scale(1.05);
 }

 .client7:hover {
     background-color: #FB3737;
     cursor: pointer;
     transform: scale(1.05);
 }

 .client8:hover {
     background-color: #00C2FF;
     cursor: pointer;
     transform: scale(1.05);
 }

 .client9:hover {
     background-color: #00C2FF;
     cursor: pointer;
     transform: scale(1.05);
 }

 .client10:hover {
     background-color: #0452C7;
     cursor: pointer;
     transform: scale(1.05);
 }

 .add_card {
     display: flex !important;
     justify-content: center !important;
     align-items: center;
     padding: 10px;
     margin-bottom: 2%;
 }

 .asp_card {
     text-align: center;
     padding: 2%
 }

 /* rcs message page */

 .rcs_content {
     background: #E4ECF8;
     width: 300px !important;
     height: 330px;
     gap: 0px;
     /* border-radius: 55px; */
 }

 .small-border {
     width: 142.72px;
     height: 160.06px;
     top: 748.27px;
     left: 243.72px;
     gap: 0px;
     opacity: 0px;
 }

 .contact_btn {
     position: fixed;
     right: -6.5%;
     top: 15%;
     display: flex;
     align-items: center;
     cursor: pointer;
     transition: width 0.3s ease;
 }

 .services_popup {
     height: 46px !important;
     width: 500px !important;
     border: 1px solid white;
     padding: 0px;
     border-radius: 10px
 }

 .rcw-client .rcw-message-text {
     background-color: #134CBC !important;
     color: white !important;
 }

 .services_sub_card {
     display: flex;
     cursor: pointer;
     justify-content: center;
     position: relative;
     top: 10px
 }

 .header_contain {
    display: flex;
    align-items: center; 
    width: 100%;
     flex-wrap: wrap;
     justify-content: center;
 }
 .event_img {
    height: 287px ;
}
.event_highlights {
    padding-left: 5%;
    padding-right: 5%;
}
.line{
    height: 400px;
    border: 1px solid #134CBC
}
.border_footer{
    border: 2px solid #FFFFFF;
     opacity: 0.1;
    margin-left:1%;
    margin-right:1%;
}
.footer_line{
     border: 1px solid #DDDDDD;
      height: 14px;
      margin: 10px 6px
}

/* .css-dip3t8{
    height: 90% !important;
} */
.break-words {
    word-break: break-word; /* Allows long words to be broken and wrap onto the next line */
    overflow-wrap: break-word; /* Ensures that the text will wrap correctly */
}
.menu_card a{
    height: 400px !important;
}

.menu_item{
    height: 50px !important;
}

.solution-menu{
    left: -42%;
}

@media (max-width:2000px) {
     .blog_page {
         display: contents !important;
     }

     .header {
         display: flex;
         justify-content: space-between;
     }
/* 
     .img-container {
         display: flex;
         justify-content: center;
     } */
     .popup_card {
        position: relative;
        left: -17px;
    }

    .popup_input {
        width: 17vw !important;
    }

    .popup_textarea {
        width: 36vw !important;

    }
 }

@media (min-width: 1920px) {
     .event-img {
         width: 16.3vw;
         height: 34vh;
     }
 }

@media (max-width:1920px) {
     .component_bgimg {
         height: 100%;
     }

     .nav-list {
         position: relative;
         left: 33%;
     }

     .contact_btn {
         right: -4.5% !important;
     }

     .main_contact {
         margin-top: 10% !important;
     }

     .border_footer {
         margin-top: 10px;
     }

     .menu-paltform {
        left: -49%;
    }
    .event_img{
        height: 315px;
    }

    .vertical_border {
        left: 10px;
    }
    .solution-menu {
        left: -24%;
    }
    .company-menu{
        margin-left: -34px;
        right: 42%;
    }
    .header_menu{
        left: -75%; 
    }
 
 }

@media (max-width:1919px) {
     .nav-list {
         position: relative;
         width: 29vw !important
     }

     .contact_btn {
         right: -5% !important;
     }
     .header_menu {
        left: -71%;
        margin-left: -32%;
    }
    .menu-paltform {
        left: -73%;
    }
    .solution-menu {
        left: -39%;
    }
 }

@media(max-width:1728px) {
    .menu-paltform {
        left: 30%;
     }
 } 

@media(max-width:1436px) {
     .contact_btn {
         right: -6% !important;
     }

     .main_contact {
         margin-top: 13% !important;
     }
     .menu-paltform {
        left: 18%;
     }
 }

@media (max-width:1347px) {
     .nav-list {
         position: relative;
         left: -8%;

     }
     .main_contact {
         margin-top: 17% !important;
     }
     .menu-paltform {
        left: 11%;
    }
 }

@media (max-width:1336px) {
     .menu-paltform {
        left: 10%;
    }
 }
@media (max-width:1037px){
    .event-img{
        width: 213px;
        height: 203px;
    }
 }
@media (max-width:952px) {
     .component {
         width: 50vw;
     }
 }

@media (max-width: 899px) {
     .header {
         display: flex;
         position: sticky;
         padding: 50px 20px;
     }

     .sidebar {
         z-index: -1;
     }

     .menu-icon {
         display: block;
         position: absolute;
         top: 25%;
         right: 5%;
         z-index: 10;
     }

     .logo {
         display: block;
         text-align: center;
         position: absolute;
         z-index: 10;
     }

     .nav {
         display: none;
     }

     .nav-list {
         display: none;
         flex-direction: column;
         margin-top: 10px;
     }

     .nav-list a {
         margin: 10px 0;
     }

     .css-vj11vy.ps-menu-root {
         display: flex;
         font-size: 20px;
     }

     .css-1t8x7v1 {
         height: 70px !important;
     }
     .css-ewdv3l {
         display: flex;
         flex-direction: column;
         justify-content: center;
         width: 100%;
         margin-left: 2% !important;
         border: 1px solid #ccc;
         margin-top: 5% !important;
     }
/* 
     .card-container {
         min-width: 100% !important;
     } */

     .main_contact {
         margin-top: 20% !important;
     }
 }

@media (min-width: 900px) {
     .menu-icon {
         display: none;
     }

     .nav {
         display: flex;
     }
     .close-btn-container-popup {
        top: -68px;
        left: 96%;
    }
 }

 /* events responsive */
@media (max-width:970px){
    .event-img {
        height: 187px;
    }
 }
@media (max-width:967px) {
     .event_highlights {
         width: 100vw !important;
     }

     .event-img {
         width: 22vw;
         height: 21vh;
     }
 }

@media (max-width:935px) {
     .event_highlights {
         width: 98vw !important;
     }

     .component_bgimg {
         height: 100%;
     }

     .event-img {
         width: 23vw;
         height: 20vh;
     }
     .popup_input {
        width: 17vw !important;
    }
 }

@media (max-width:927px) {
     .event_highlights {
         width: 90vw !important;
     }

     .card_event {
         width: 386px;
         height: 494px;
         margin-left: 0px !important;
     }

     .event-img {
         width: 206px;
         height: 182px;
     }

     .Line {
         height: 300px !important;
     }

     .header_enterprise {
         margin-right: 0%;
     }
 }

@media (max-width:876px) {
     .blog_img {
         height: 36vh !important;
     }

     .social_links {
         margin-left: 0 !important;
     }
     .close-btn-container-popup {
        top: -66px;
    }
 }

@media (max-width:800px) {
     .event_highlights {
         width: 100vw;
     }

     .card_event {
         width: 331px;
         height: 494px;
     }

     .event-img {
         width: 178px;
         height: 168px;
     }

     .line {
         height: 341px !important;
     }
 }

@media (max-width: 768px) {
     .nav-list {
         flex-direction: column;
     }

     .header:hover .nav-list {
         display: flex;
     }
     .css-dip3t8 {
         margin-top: 3%;
     }

     .main_contact {
         margin-top: 30% !important;
     }
     .content {
        font-size: 20px;
        line-height: 1.4;
    }

    .home-button {
        width: 80%;
        margin-left: 0%;
    }
      /* .img-container {
         width: 44vw;
         height: 60vh;
     } */
 }

@media (max-width:732px) {
     .event_highlights {
         width: 100vw;
     }

     .card_event {
         width: 310px;
         height: 494px;
         margin-left: 0px !important;
     }

     .event-img {
         width: 172px;
         height: 178px;
     }

     .Line {
         height: 300px !important;
     }

     .service_card {
         display: flex !important;
         justify-content: center !important;
         align-items: center !important;
         flex-direction: column !important;
     }

     .sub_card {
         width: 80vw !important;
     }

     .bulk_card_container {
         display: flex !important;
         justify-content: center !important;
         align-items: center !important;
         flex-direction: column !important;
     }
 }

@media (max-width:700px) {
     .event_highlights {
         width: 100vw;
     }

     .card_event {
         width: 296px;
         height: 494px;
         margin-left: 0px !important;
     }

     .event-img {
         width: 161px;
         height: 152px;
     }

     .Line {
         height: 300px !important;
     }

     .add_card {
         display: flex !important;
         justify-content: center !important;
         align-items: center;
         flex-direction: column !important;
     }

     .add_sub_card {
         width: 70vw !important;
     }
 }
@media (max-width:625px) {
     .event_highlights {
         width: 100vw !important;
     }

     .event-img {
         width: 140px;
         height: 130px;
     }

     .line {
         height: 300px !important;
     }

     .event_highlights {
        width: 88vw !important;
    }

    .line {
        height: 337px !important;
    }
 }
 @media (max-width: 600px) {
     .footer-text {
         font-size: 12px;
     }

     .footer-icons img {
         width: 18px;
         height: 18px;
     }

     .popup_body {
        padding: 8px;
    }

    .popup_img_container {
        max-width: 80%;
    }

    .popup_textarea {
        width: 51vw !important;
        margin-left: 3px;
    }

    .popup_card {
        padding-top: 55% !important;
        padding-left: 9% !important;
    }
    .payment_form {
        padding: 10px !important;
    }
    .cookie_btn {
        width: 100%;
    }
    .cookies_main_btn {
        display: flex !important;
        flex-direction: row !important;
        gap: 10px !important;
    }
    .cookie_main {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center
    }
    /* .client_main_card .css-1b12sfc-MuiPaper-root {
        width: 33% !important;
     } */
 }
 @media (max-width:599px) {
     .home_detail {
         display: flex;
         flex-direction: column;
     }
 }
 @media (max-width: 579px) {
     .container {
         display: flex;
         flex-direction: column;
         align-items: center;
     }

     .card_event {
         width: 420px;
         height: 494px;
         margin-left: 0px !important;
     }

     .event-img {
         width: 223px;
         height: 214px;
     }

     .line {
         height: 404px !important;
     }
 }

 @media (max-width:567px) {
     .blog_card {
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
     }

     .services_popup {
        width: 300px !important;
        height: 75px !important;
    }

     .home_main {
         margin-top: 16% !important;
     }

     .main_contact {
         margin-top: 35% !important;
     }

     .services_sub_card {
         padding: 10px;
     }
 }
 @media (max-width:549px) {

     .container {
         display: flex;
         flex-direction: column;
         align-items: center;
     }

     .card_event {
         width: 370px;
         height: 494px;
         margin-left: 0px !important;
     }

     .event-img {
         width: 198px;
         height: 198px;
     }

     .line {
         height: 404px !important;
     }
 }

 @media (max-width:519px) {
     .container {
         display: flex;
         flex-direction: column;
         align-items: center;
     }

     .card_event {
         width: 370px;
         height: 494px;
         margin-left: 0px !important;
     }

     .line {
         height: 404px !important;
     }
 }

 @media (max-width:496px) {
     .container {
         display: flex;
         flex-direction: column;
         align-items: center;
     }

     .card_event {
         width: 311px;
         height: 494px;
         margin-left: 0px !important;
     }

     .event-img {
         width: 170px;
         height: 167px;
     }

     .line {
         height: 404px !important;
     }

     .services_popup {
        width: 223px !important;
        height: 123px !important;
    }
     .main_contact {
         margin-top: 40% !important;
         padding: 14px;
     }
 }

 /* events responsive */
 @media (max-width:1245px) {
     .nav-list {
         position: relative;
     }
 }

 @media (max-width: 1199px) {
     .img_main {
         display: flex;
         justify-content: center;
         align-items: center;
         height: 100%;
     }

     .header {
         display: flex;
         position: sticky;
         padding: 50px 20px;
     }

     .sidebar {
         z-index: -1;
     }

     .menu-icon {
         display: block;
         position: absolute;
         top: 25%;
         right: 5%;
         z-index: 10;
     }

     .logo {
         display: block;
         text-align: center;
         position: absolute;
         z-index: 10;
     }

     .nav {
         display: none;
     }

     .dropdown-menu {
         display: flex;
         flex-direction: column;
         position: relative;
         background-color: white;
         box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
         z-index: 100;
         width: 100vw;
         padding-left: 10px;
     }

     .dropdown_options {
         display: flex;
         flex-direction: column;
         justify-content: center;
     }

     .nav-list {
         display: none;
         flex-direction: column;
         margin-top: 10px;
     }

     .nav-list a {
         margin: 10px 0;
     }

     .css-vj11vy.ps-menu-root {
         display: flex;
         font-size: 20px;
     }

     .css-1t8x7v1 {
         height: 70px !important;
     }

     .home_main {
         margin-top: 10% !important;
     }

     .css-ewdv3l {
         display: flex;
         flex-direction: column;
         justify-content: center;
         width: 100%;
         margin-left: 2% !important;
         border: 1px solid #ccc;
         margin-top: 5% !important;
     }
/* 
     .card-container {
         min-width: 100% !important;
     } */

     .main_contact {
         margin-top: 20% !important;
     }
  
    .menu_card{
        height: 425px !important;
    }

       /* .card-container {
         display: flex;
         justify-content: center;
         flex-direction: row;
         min-width: 100%;
     }

     .main_container {
         display: flex;
         justify-content: center;
     } */

     .card {
        flex: 0 0 auto;
        margin: 0;
    }
 }
 @media (max-width:1185px) {
     .img_main {
         display: flex;
         justify-content: center;
     }
/* 
     .card-container {
        display: flex;
        justify-content: center;
    } */
 }

 @media (max-width: 1160px) {
     .img_main {
         display: flex;
         justify-content: center;
         align-items: center;
         height: auto;
         margin: 0 auto;
     }

     .card {
         margin: 10px;
     }

     .card_icon {
         text-align: center;
     }

     h1 {
         font-size: 1.5rem;
         text-align: center;
     }
     /* .card-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        height: auto;
    } */
 }
 @media (max-width:1050px) {
     .contact_btn {
         margin-left: 3% !important;
     }
 }

 @media (max-width:1024px) {
     .contact_btn {
         margin-left: 0% !important;
     }
 }

 @media (max-width:1014px) {
     .nav-list div {
         font-size: 13px;
     }
 }

 @media (max-width: 1010px) {
     /* .img-container {
         width: 34vw;
         height: 60vh;
     } */

     .footer_container {
         display: flex;
         flex-wrap: wrap;
     }

     .footer_address {
         margin-left: 10%;
     }

     .footer_links {
         margin-left: 9% !important;
     }
/* 
     .card-container {
         gap: 10%;
     } */

     .card_icon {
         text-align: center;
     }
 }

 /* @media (max-width: 1004px) {
     .card-container {
         display: flex;
         flex-direction: row;
         max-width: none;
     }
 } */

 /* @media (max-width:1000px) {
     .card-container {
         display: flex;
         flex-wrap: wrap;
         justify-content: center;
         align-items: center;
         height: auto;
     }
 } */

 @media (max-width:991px) {
     .nav-list div {
         margin-left: 0px;
         font-size: 12px;
     }
 }

 @media (max-width:940px) {
     .nav-list div {
         margin-left: 0px;
         font-size: 12px;
     }
 }

 @media (max-width:946px) {
     .nav-list div {
         margin-left: 0px;
         font-size: 12px;
     }
 }

 @media (max-width:931px) {
     .nav-list div {
         margin-left: 0px;
         font-size: 12px;
     }
 }

 @media (max-width:921px) {
     .nav-list div {
         margin-left: 0px;
         font-size: 12px;
     }

     /* .img-container {
         width: 35vw;
         height: 60vh;
     } */
 }

 @media (max-width:885px) {
     .card-container {
         display: flex;
         flex-wrap: wrap;
         justify-content: center;
         align-items: center;
         height: auto;
         min-width: 100% !important;
     }
 }

 /* @media (max-width:749px) {
     .card-container {
         display: flex;
         flex-wrap: wrap;
         margin-left: 10%;
         justify-content: center;
         align-items: center;
         height: auto;
     }
 }

 @media (max-width:700px) {
     .img-container {
         width: 45vw;
         height: 72vh;
     }
 } */

 @media (max-width:669px) {
    .event_highlights {
        width: 100vw;
    }

    .card_event {
        width: 253px;
        height: 494px;
        margin-left: 0px !important;
    }

    .event-img {
        width: 140px;
        height: 145px;
    }

    .Line {
        height: 300px !important;
    }
}

 /* @media (max-width:622px) {
     .card-container {
         margin-right: 10%;
     }

     .img-container {
         width: 53vw;
         height: 53vh;
         display: flex;
         justify-content: center;
     }
 }
 @media (max-width:617px) {
     .card-container {
         display: flex;
         flex-wrap: wrap;
         margin-left: 10%;
         justify-content: center;
         align-items: center;
         height: auto;
     }
 }

 @media (max-width:599px) {
     .card-container {
         display: flex;
         flex-wrap: wrap;
         justify-content: center;
         align-items: center;
         height: auto;
     }
 } */

 @media (max-width:593px) {
     .cookie_sub {
         margin-top: 10px;
         display: flex;
         flex-direction: column;
     }

     .cookies_main_btn {
         display: 'flex';
         flex-wrap: nowrap;
     }
 }
 @media (max-width: 571px) {
    .certificate_card {
        width: 342px;
    }
     .services_card{
        width: 430px !important;
     }
 }
 @media (max-width:509px) {
     .services_sub_card {
         padding: 10px;
     }
     .home_btn {
        display: flex;
        justify-content: center;
    }
 }

 @media (max-width: 500px) {
    .certificate_card {
        width: 364px;
    }

     .content {
         font-size: 18px;
         line-height: 1.3;
     }

     .home-button {
         width: 100%;
         margin-left: 0;
     }

     .sidebar{
        width: 100% !important;
        left: 0px !important;
     }
 }

 @media (max-width: 480px) {
     .content {
         font-size: 18px;
         line-height: 1.3;
     }

     .home-button {
         width: 100%;
         margin-left: 0;
     }

     .event {
         height: 200px !important;
         width: 278px !important;
     }

     .event_img {
         height: 178px ;
         width: 273px ;
     }

     .services_sub_card {
         padding: 18px;
         text-align: center;
     }

     /* .img-container {
         width: 63vw;
         height: 57vh;
     } */

     .bUvHsa {
         height: 85px;
     }

     .cyAgpG {
         height: calc(337px) !important;
     }

     .iduRWJ {
         width: 353px !important;
         height: 481px !important;
     }
     .beqZzV{
        width: 353px !important;
        height: 481px !important;
     }
 }

 @media (max-width:438px) {
     /* .img-container {
         width: 63vw;
         height: 48vh
     } */
.client_card{
    width: 260px !important;
}
     .infra_sub_cards {
         width: 277px !important;
     }
     .services_card{
        width: 346px !important;
     }
     .it_services_card{
        margin-top: 10%;
     }
 }

 @media (max-width:421px) {
     .img_main {
         display: flex;
         justify-content: center;
     }

     .big_card {
         width: 270px !important;
         height: 485px !important;
     }

     .css-dip3t8 {
         margin-top: 7%;
     }
     .close-btn-container-popup {
        top: -164px;
        left: 94%;
    }
    .main_header {
        padding-top: 15px;
    }
 }


 @media (max-width: 376px) {
    .certificate_card {
        width: 234px;
    }

     .item {
         width: 249px !important;
     }

     .header_contain {
         flex-wrap: nowrap !important;
     }
     .card_spe {
         width: 240px !important;
     }
     .services_card{
        height: 274px !important;

     }
     .event-img {
        width: 140px;
        height: 132px;
    }

     /* .img-container {
         width: 63vw;
         height: 40vh;
     } */
     .services_popup {
        width: 174px !important;
        height: 165px !important;
    }
    .it_services_card {
        margin-top: 26%;
    }

 }
 @media (max-width:332px) {
     .event_img {
         height: 153px ;
         width: 240px ;
     }

     .event {
         height: 154px !important;
         width: 244px !important;
     }

     .review_sub {
         width: 256px !important;
         height: 235px !important;
     }
     .css-dip3t8 {
         margin-top: 10%;
     }
 }

 /* .client_main_card .css-1b12sfc-MuiPaper-root {
    width: auto !important;
 }
 @media(min-width:1400px){
    .client_main_card .css-1b12sfc-MuiPaper-root {
        height: 200px !important;
     }
    .client_main_card .css-1bq9544-MuiGrid2-root {
        width: 20% !important;
     }
 } */

 /* .css-1bq9544-MuiGrid2-root {
    width: auto !important;
 } */
 .card_grid {
    width: auto !important;
 }

 .client_main_cards {
    padding:16px 10px 10px 6px;
  }
  
  .client-card {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 243px;
    height: 156px;
    display: flex;
    flex-direction: column;
    justify-content: center;  /* Vertically centers the content */
    align-items: center;  /* Horizontally centers the content */
  }
  
  .client-image {
    /* width: 100px; 
    height: 100px;  */
    object-fit: cover; /* Ensures image fits well within the square */
    border-radius: 8px; /* Optional: if you want to slightly round the corners of the image */
  }
  
  .client-name {
    margin-top: 15px; /* Add some space between the image and the name */
    font-size: 16px;
    color: "#161011";
  }
/* For screens between 1200px and 1400px */
@media (min-width: 1200px) and (max-width: 1399px) {
    .client-card {
      width: 260px;
      height: 165px;
    }
  }
  
  /* For screens between 1400px and 1560px */
  @media (min-width: 1400px) and (max-width: 1560px) {
    .client-card {
      width: 275px;
      height: 175px;
    }
  }
  
  /* For screens between 1560px and 1800px */
  @media (min-width: 1560px) and (max-width: 1650px) {
    .client-card {
      width: 295px;
      height: 175px;
    }
  }
  @media (min-width: 1661px) and (max-width: 1750px) {
    .client-card {
      max-width: 415px !important;
      height: 175px;
    }
  }
  
  /* For screens between 1800px and 2000px */
  @media (min-width: 1801px) and (max-width: 2000px) {
    .client-card {
      width: 295px;
      height: 180px;
    }
  }
  
  /* For screens wider than 2000px */
  @media (min-width: 2001px) {
    .client-card {
      width: 305px;
      height: 185px;
    }
  }

  
  
  