/* Global Style */
.w-100-perc{
    width:100%
}

.pt-2-perc{
    padding-top: 2%;
}

.color-134CBC{
    color:#134CBC
}

.blog_line{
    border: 1px solid #134CBC;
    height: 20px;
    margin: 0 10px
}

/* blog page */
.image_component {
    height: 100%;
    width: 74vw; 
    display: block;
    margin: 0 auto;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5)
}

.blog_card{
    display: flex;
    justify-content: center;
    padding: 3%;
}
.blog_img{
    height:200px;
    width:260px
}
.blog_arrow{
     text-decoration: none;
      color: #134CBC; 
      font-size: small;
      text-align:left
}
.blog_content{
    /* text-align: center; */
    margin-right: 15%;
    margin-left: 15%;
}
.blog_sub_img{
    width:50vw;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
    margin-top: 10px
}
.chatbot_header{
    border: 0.5px solid #00000040;
     height: 14px;
     margin: 15px 6px
}
.blog_sub_img{
    text-align: center;
    width: 50vw; 
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5); 
    margin-top: 10px
}